import { Routes, Route } from "react-router-dom";
import Query from "./pages/Query";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/Login";
import Upload from "./pages/Upload";

function App() {
  return (
    <Routes>
      <Route index path="" element={<Query />} />
      <Route path="login" element={<Login />} />
      <Route path="upload" element={<Upload />} />
      <Route path="error" element={<ErrorPage status={500} text="Server Error" />} />
      <Route path="*" element={<ErrorPage status={404} text="This page could not be found" />} />
    </Routes>
  );
}

export default App;
