import styles from './PageHeader.module.scss'


const PageHeader = () => {
    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>宁波骏道供应链管理有限公司</div>
            </div>
            <div className={styles.divider}></div>
        </>
    )
}


export default PageHeader