import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import styles from './Upload.module.scss'
import { read, utils } from 'xlsx'
import { ReactComponent as UploadIcon } from "../assets/upload.svg"
import { ReactComponent as DeleteIcon } from '../assets/delete.svg'
import PageHeader from "../components/PageHeader"
import LoadingButton from '../components/LoadingButton'


const Upload = () => {
    const navigator = useNavigate()
    const inputRef = useRef()
    const [message, setMessage] = useState(null)
    const [success, setSuccess] = useState(null)
    const [file, setFile] = useState(null)
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        // cookie 中没有 token 时跳转至登录页面
        if (!document.cookie.includes('token=')) {
            navigator('/login', { replace: true })
        }
    }, []) // eslint-disable-line

    // 读取电子表格
    const readFile = () => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.onload = () => {
                const wb = read(reader.result, { cellDates: true, dateNF: 'yyyy-mm-dd' })
                const ws = wb.Sheets[wb.SheetNames[0]]
                const data = utils.sheet_to_json(ws, { defval: '', raw: false })
                resolve(data)
            }
            reader.onerror = () => {
                setUploading(false)
                setMessage('读取文件发生了错误')
            }
            reader.readAsArrayBuffer(file)
        })
    }

    // 上传文件
    const onUpload = () => {
        // 判断是否选择文件
        if (file === null) {
            setMessage('还没有选择要上传的文件')
            return
        }

        // 文件上传时，设置上传按钮状态为上传中
        setUploading(true)

        // 清空临时信息
        setMessage(null)
        setSuccess(null)

        readFile().then(data => {
            // 调用文件上传接口
            fetch(`http://api.steeddoscm.com/api/upload`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: data
                }),
                credentials: 'include'
            })
                .then(response => response.json())
                .then(data => {
                    // 用户未登录
                    if (data.msg === 'Unauthorized') {
                        navigator('/login', { replace: true })
                        return
                    }

                    // 文件上传完成后，取消上传状态
                    setUploading(false)
                    if (data.success) {
                        // 文件上传成功，清空文件列表
                        setFile(null)
                        inputRef.current.value = ''
                        setSuccess('文件上传成功')
                    } else {
                        // 文件上传失败，设置错误信息
                        setMessage(data.msg)
                    }
                })
                .catch(error => {
                    // 文件上传过程中抛出异常，取消上传状态，并设置错误信息
                    setUploading(false)
                    setMessage('网络错误，文件上传失败')
                })
        })
    }

    // 单击文件上传区域时调用 input 的 click 方法
    const onChoseFile = () => {
        inputRef.current.click()
    }

    // 将选择的文件中符合格式要求的赋值至状态变量中
    const selectFile = fs => {
        if (fs.length !== 0) {
            const f = fs[0]
            if (f.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setFile(f)
            } else {
                setMessage('必须是以 .xlsx 结尾的电子表格文件')
            }
        }
    }

    // 拖动文件至文件上传区域时触发
    const onDropFile = e => {
        // 取消默认事件
        e.preventDefault()
        e.stopPropagation()

        clear()
        inputRef.current.value = ''
        const fs = Array.from(e.dataTransfer.files)
        selectFile(fs)
    }

    // input 内容改变时触发
    const onChangeFile = () => {
        clear()
        const fs = Array.from(inputRef.current.files)
        selectFile(fs)
    }

    // 单击文件后的删除图标时清空文件和临时信息，并讲 input 置空
    const removeFile = () => {
        clear()
        inputRef.current.value = ''
    }

    // 清除文件和临时信息
    const clear = () => {
        setFile(null)
        setMessage(null)
        setSuccess(null)
    }

    return (
        <>
            <PageHeader />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div
                        className={styles['upload-box']}
                        onClick={onChoseFile}
                        onDrop={onDropFile}
                        onDragOver={e => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                        <input type="file" hidden ref={inputRef} onChange={onChangeFile} />
                        <UploadIcon className={styles.icon} />
                        <div className={styles.description}>单击或拖动文件到这个区域上传</div>
                    </div>
                    <div className={styles.message} hidden={message === null}>{message}</div>
                    <div className={styles.success} hidden={success === null}>{success}</div>
                    <div className={styles.filename} hidden={file === null}>
                        {file ? file.name : null}
                        <div className={styles['remove-btn']} onClick={removeFile}>
                            <DeleteIcon className={styles['delete-icon']} />
                        </div>
                    </div>
                    <LoadingButton
                        className={styles['upload-btn']}
                        onClick={onUpload}
                        loading={uploading}
                    >
                        上传
                    </LoadingButton>
                </div>
            </div>
        </>
    )
}

export default Upload