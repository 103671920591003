import styles from './ErrorPage.module.scss'

const ErrorPage = props => {
    return (
        <div className={styles.content}>
            <h1>{props.status}</h1>
            <h2>{props.text}</h2>
        </div>
    )
}

export default ErrorPage