import { useRef, useState } from 'react'
import styles from './Login.module.scss'
import CryptoJS from 'crypto-js'
import { useNavigate } from 'react-router-dom'
import PageHeader from '../components/PageHeader'
import LoadingButton from '../components/LoadingButton'

const Login = () => {
    const navigator = useNavigate()
    const usernameRef = useRef()
    const passwordRef = useRef()
    const [usernamePlaceholder, setUsernamePlaceholder] = useState('用户名')
    const [passwordPlaceholder, setPasswordPlaceholder] = useState('密码')
    const [message, setMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const onLogin = () => {
        // 点击登录按钮时清空错误信息
        setMessage(null)

        // 检查用户输入
        if (usernameRef.current.value.length === 0 || passwordRef.current.value.length === 0) {
            if (usernameRef.current.value.length === 0) {
                setUsernamePlaceholder('请输入用户名')
                usernameRef.current.classList.add(styles.warn)
            }
            if (passwordRef.current.value.length === 0) {
                setPasswordPlaceholder('请输入密码')
                passwordRef.current.classList.add(styles.warn)
            }
            return
        }

        // 访问登录接口时，设置按钮为加载状态
        setLoading(true)

        // 访问登录接口
        fetch(`http://api.steeddoscm.com/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: usernameRef.current.value,
                password: CryptoJS.MD5(passwordRef.current.value).toString()
            }),
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                // 接口请求完成后取消按钮的加载状态
                setLoading(false)
                if (!data.success) {
                    // 服务器处理未成功，设置接口返回的错误信息
                    setMessage(data.msg)
                } else {
                    // 登录成功后跳转至 /#/upload 路由
                    navigator('/upload', { replace: true })
                }
            })
            .catch(error => {
                // 接口请求过程中报错，设置错误信息，取消按钮的加载状态
                setMessage('网络错误')
                setLoading(false)
            })
    }

    return (
        <>
            <PageHeader />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div className={styles.title}>登录</div>
                    </div>
                    <div className={styles.formdata}>
                        <div className={styles.row}>
                            <input placeholder={usernamePlaceholder} spellCheck={false} ref={usernameRef}></input>
                        </div>
                        <div className={styles.row}>
                            <input placeholder={passwordPlaceholder} spellCheck={false} type='password' ref={passwordRef}></input>
                        </div>
                        <div className={styles.row} hidden={message === null}>
                            <div className={styles.message}>{message}</div>
                        </div>
                        <div className={styles.row}>
                            <LoadingButton
                                onClick={onLogin}
                                loading={loading}
                                className={styles['login-btn']}
                            >
                                登录
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login