import styles from './PageFooter.module.scss'


const PageFooter = () => {
    return (
        <>
            <div className={styles.footer}>
                <div className={styles.divider}></div>
                <div className={styles.content}>
                    <a
                        href="http://www.beian.miit.gov.cn/"
                        target="_blank"
                        className={styles.icp}
                        rel='noreferrer'
                    >
                        浙ICP备2023036652号-1
                    </a>
                    <a
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020002001012"
                        target="_blank"
                        className={styles.icp}
                        rel='noreferrer'
                    >
                        浙公网安备33020002001012号
                    </a>
                </div>
            </div>
        </>
    )
}


export default PageFooter