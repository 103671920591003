import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import styles from './LoadingButton.module.scss'

const LoadingButton = forwardRef((props, ref) => {
    const swtichBtnRef = useRef()

    useImperativeHandle(ref, () => ({
        click: () => swtichBtnRef.current.click()
    }))

    useEffect(() => {
        if (props.loading) {
            swtichBtnRef.current.classList.add(styles.loading)
        } else {
            swtichBtnRef.current.classList.remove(styles.loading)
        }
    }, [props.loading])


    const classList = [styles.btn]
    if (props.className) {
        classList.push(...props.className.split(' '))
    }

    return (
        <div className={classList.join(' ')} onClick={props.onClick} ref={swtichBtnRef} >
            <div className={styles.circle} hidden={!props.loading}></div>
            {props.children}
        </div>
    )
})


export default LoadingButton