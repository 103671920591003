import { useRef, useState } from 'react'
import styles from './Query.module.scss'
import PageHeader from '../components/PageHeader'
import PageFooter from '../components/PageFooter'
import LoadingButton from '../components/LoadingButton'

const Query = () => {
    const searchInputRef = useRef()
    const queryBtnRef = useRef()
    const [data, setData] = useState(null)
    const [message, setMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const onQuery = () => {
        if (searchInputRef.current.value.length === 0) {
            setMessage('请输入要查询的柜号')
            return
        }

        setMessage(null)

        setLoading(true)
        fetch(`http://api.steeddoscm.com/api/query`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                containerId: searchInputRef.current.value
            })
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false)
                if (data.success) {
                    setData(data.data)
                } else {
                    setData(null)
                    setMessage(data.msg)
                }
            })
            .catch(error => {
                setData(null)
                setLoading(false)
                setMessage('网络错误')
            })
    }

    const onKeyDown = e => {
        if (e.key === 'Enter') {
            queryBtnRef.current.click()
        }
    }

    return (
        <div className={styles.container}>
            <PageHeader />
            <div className={styles.content}>
                <div className={styles.search}>
                    <input ref={searchInputRef} placeholder="柜号" onKeyDown={onKeyDown}></input>
                    <LoadingButton
                        className={styles['search-btn']}
                        onClick={onQuery}
                        loading={loading}
                        ref={queryBtnRef}
                    >
                        查询
                    </LoadingButton>
                </div>
                <div className={styles.message}>{message}</div>
                <div className={styles.result}>
                    <div className={styles.label}>{data && `“${data['柜号']}”`}基本信息</div>
                    <div className={styles.body}>
                        <table cellSpacing={0} cellPadding={0} border={0} >
                            <colgroup>
                                <col width="25%"></col>
                                <col width="75%"></col>
                            </colgroup>
                            <tbody>
                                {
                                    data !== null ?
                                        Object.keys(data).map(name => {
                                            return (
                                                <tr key={name}>
                                                    <td rowSpan={1} colSpan={1} className={styles.name}>{name}</td>
                                                    <td rowSpan={1} colSpan={1} className={styles.value}>{data[name]}</td>
                                                </tr>
                                            )
                                        }) : ['柜号', '仓点', 'ETA', '卸船', 'LFD', '提柜', '预约', '还空', '异常', '备注'].map(name => {
                                            return (
                                                <tr key={name}>
                                                    <td rowSpan={1} colSpan={1} className={styles.name}>{name}</td>
                                                    <td rowSpan={1} colSpan={1} className={styles.value}></td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={styles.notice}>
                    <div>1. 系统更新时间工作日11:30，下午4:30（<span className={styles.emphasis}>更新后数据即为当下准确数据</span>）</div>
                    <div>2. <span className={styles.emphasis}>仅可查询已到港的柜子信息</span>，所有柜子初始预约时间和还空时间均会邮件告知，推约情况和凭证会邮件告知</div>
                    <div>3. 提柜时间在卸船后的<span className={styles.emphasis}>第一个工作日或第二个工作日</span>会有，<span className={styles.emphasis}>遇上国外双休，不会有提柜约</span></div>
                    <div>4. 所有数据均为人工后台输入，如有更新不及时请见谅，请尽量在更新时间后进行查询，如有紧急事项请邮件或企业微信告知</div>
                </div>
            </div >
            <PageFooter />
        </div>
    )
}


export default Query